<template>
  <div class="add-goods">
    <el-form
      :model="goodsForm"
      :rules="goodsRules"
      ref="goodsForm"
      class="demo-goodsForm"
      hide-required-asterisk
      label-width="100px"
      label-position="left"
    >
      <el-form-item label="商品名称" prop="name">
        <el-input
          v-model="goodsForm.name"
          placeholder="请输入商品名称"
          class="text-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="售价" prop="price">
        <el-input
          v-model="goodsForm.price"
          placeholder="请输入售价"
          class="text-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="销量" prop="salesVolume">
        <el-input
          v-model="goodsForm.salesVolume"
          placeholder="请输入销量"
          class="text-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品封面" prop="cover">
        <upload-img
          :fixedNumber="[20, 20]"
          fixedName="1:1"
          accept="image/*"
          tip="推荐图片尺寸宽高比1:1"
          uploadName="上传封面"
          @uploadInfo="uploadInfo"
          :fixedFlag="true"
        />
      </el-form-item>
      <el-form-item label="商品主图">
        <multi-upload @avatarFile="avatarFile" @removeFile="removeFile" />
      </el-form-item>
      <el-form-item label="商品详情页">
        <multi-upload
          @avatarFile="detailsFile"
          @removeFile="removeDetailsFile"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('goodsForm')"
          class="blue-btn"
          :loading="submitLoading"
          >确认创建</el-button
        >
        <el-button @click="resetForm('goodsForm')" class="gray-btn"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadImg from "../../../components/UploadImg";
import MultiUpload from "../../../components/MultiUpload";
import { addGoods } from "@/api/goods";
import { uploadOss } from "@/api/login";
export default {
  components: { UploadImg, MultiUpload },
  data() {
    return {
      goodsForm: {
        name: "",
        price: "",
        salesVolume: "",
        cover: "",
      },
      mainImage: [],
      detailsList: [],
      goodsRules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        price: [{ required: true, message: "请输入商品售价", trigger: "blur" }],
        salesVolume: [
          { required: true, message: "请输入商品销量", trigger: "blur" },
        ],
        cover: [
          { required: true, message: "请上传商品封面", trigger: "change" },
        ],
      },
      submitLoading: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 获取上传封面
    uploadInfo(info) {
      this.goodsForm.cover = info;
    },
    // 上传主图
    avatarFile(file, fileList) {
      // this.imgFile = file;
      // console.log("file,fileList :>> ", file, fileList);
      this.mainImage = fileList;
    },
    removeFile(file, fileList) {
      this.mainImage = fileList;
    },
    // 上传详情
    detailsFile(file, fileList) {
      this.detailsList = fileList;
    },
    removeDetailsFile(file, fileList) {
      this.detailsList = fileList;
    },
    // 新建商品
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.mainImage.length === 0) {
            this.$message.error("请上传商品主图");
            return;
          }
          if (this.detailsList.length === 0) {
            this.$message.error("请上传商品详情页");
            return;
          }
          this.submitLoading = true;
          let mainImageArr = [];
          for (let i = 0; i < this.mainImage.length; i++) {
            let promise = new Promise((resolve, reject) => {
              let formData = new FormData();
              let file = this.mainImage[i].raw;
              formData.append("file", file);
              formData.append("fileDir", "goodsImg");
              uploadOss(formData).then((res) => {
                // console.log(res);
                if (res.code === 200) {
                  resolve(res.result);
                  return;
                }
                reject(res.result);
              });
            });
            mainImageArr.push(promise);
          }
          const mainImage = await Promise.all(mainImageArr);
          console.log(mainImage);
          let detailsArr = [];
          for (let i = 0; i < this.detailsList.length; i++) {
            let promise = new Promise((resolve, reject) => {
              let formData = new FormData();
              let file = this.detailsList[i].raw;
              formData.append("file", file);
              formData.append("fileDir", "goodsImg");
              uploadOss(formData).then((res) => {
                // console.log(res);
                if (res.code === 200) {
                  resolve(res.result);
                  return;
                }
                reject(res.result);
              });
            });
            detailsArr.push(promise);
          }
          const details = await Promise.all(detailsArr);
          console.log("details :>> ", details);
          const params = {
            ...this.goodsForm,
            details: details.join(","),
            mainImage: mainImage.join(","),
          };
          console.log("params :>> ", params);
          await addGoods(params);
          this.$message.success("添加成功！");
          this.$router.go(-1);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      // this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.add-goods {
  ::v-deep .list-scrollbar {
    // max-height: 40vh;
    padding: 20px 0;
    width: 60%;
    .rhythm-box {
      > .el-form-item__content {
        border: 1px solid #dbdfdf;
      }
    }
    .add-rhythm {
      margin-top: 25px;
      padding: 10px 0;
      background: rgba(94, 131, 245, 0.16);
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: #5e83f5;
      line-height: 21px;
      cursor: pointer;
      img {
        width: 21px;
        height: 21px;
        margin-bottom: 5px;
      }
    }
    .el-form-item__error {
      // white-space: nowrap;
      padding: 0;
    }
  }
  .text-input {
    width: 200px;
  }
  ::v-deep .title-row {
    background: #f5f6f6;
    border-radius: 2px;
    height: 32px;
    .el-col-4,
    .el-col-5 {
      text-align: center;
      height: 32px;
      font-size: 14px;
      color: #696969;
      line-height: 32px;
    }
  }
  ::v-deep .item-box {
    margin-top: 16px;
    .el-col-5 {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-input {
        width: 130px;
        text-align: center;
        .el-input__inner {
          text-align: center;
          height: 32px;
          line-height: 32px;
        }
        .el-input__icon {
          height: 32px;
          line-height: 32px;
        }
      }
    }
    .el-col-4 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      span {
        font-size: 16px;
        color: #ee3a3a;
        line-height: 21px;
        cursor: pointer;
      }
    }
  }
  ::v-deep .star-form {
    .el-rate {
      height: 32px;
      line-height: 32px;
      border-radius: 2px;
      border: 1px solid #dbdfdf;
      width: 200px;
      text-align: center;
      .el-rate__item {
        .el-rate__icon {
          font-size: 28px;
        }
        // .el-rate__decimal {
        //   color: #ce3535; /* 修改为需要的颜色 */
        // }
      }
    }
  }
}
</style>
